.Register {
    .name {
        display: flex;

        .Input {
            min-width: none!important;
            width: 5rem!important;

            input {
                width: 10px;
            }
        }
    }
}