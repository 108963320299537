.Dashboard {
  height: 100vh;
  display: flex;

  .sidePanel {
    background: rgb(246, 246, 246);
    border: 1px solid rgb(233, 233, 233);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    isolation: isolate;
    margin: 30px;
    margin-right: 0;
    border-radius: 10px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

    img {
      width: 7rem;
      cursor: pointer;
    }

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    .indicator {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 20px;
      background: rgb(235, 235, 235);
      z-index: -1;
      transition: all 0.25s ease;
      border-radius: 10px;
    }

    .pages {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .page {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        width: 100%;
        padding: 5px 20px;
        border-radius: 10px;
        transition: color 0.25s ease;
        min-width: 15rem;

        &.active {
          color: var(--brand);
        }

        &:hover {
          color: var(--brand);
          transition-duration: 0s;
        }
      }
    }

    .account {
      width: 100%;
      padding: 0 20px;

      .email {
        color: rgb(122, 122, 122);
        margin-bottom: 10px;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 10px;
        width: 100%;
        padding: 5px 20px;
        background: none;
        border: 1px solid var(--danger);

        color: var(--danger);

        .icon {
          stroke: var(--danger);
        }

        &:hover {
          background: var(--danger);
          color: white;

          .icon {
            stroke: white;
          }
        }
      }
    }
  }

  .mainPanel {
    padding: 30px;
    width: 100%;
  }
}

.DashHome {
  .indicator {
    background: var(--danger);
    display: flex;
    width: max-content;
    padding: 5px 15px;
    border-radius: 999px;
    color: white;
    font-size: 0.7rem;

    &.verified {
      background: var(--brand);
    }
  }
}

.DashMembers {
  width: 100%;

  .addMember {
    display: flex;
    gap: 10px;

    button {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.editUser {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .row {
    display: flex;
    gap: 10px;

    .confirm {
      padding: 0 10px;
    }
  }
}
