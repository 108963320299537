.gpssim {
    width: 800px;
    height: 800px;
    background: grey;
    position: relative;

    &::after {
        content: "'Plejehjem'";
        font-size: 3rem;
        top: 50%;
        left: 50%;
        width: 300px;
        height: 300px;
        background: lightgray;
        position: absolute;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }
}