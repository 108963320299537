@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

// Variables
:root {
  --brand: #7277d6;
  --brand-dark: #6367b4;
  --danger: #ee5252;
  --passive: #939393;
  --surface-0: #ededed;
  --surface-1: white;
  --disabled: rgb(172, 172, 172);

  --radius: 12px;
  --border: 1px solid #d6d6d6;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

a {
  color: var(--brand);
  text-decoration: underline;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;

  th {
    text-align: left;
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
  }

  td {
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
  }

  tr:nth-child(even) {
  }

  tr:hover:not(:nth-child(1)) {
    background-color: #f9f9f9;
  }

  .actions {
    display: flex;
    gap: 10px;

    button {
      width: 30px;
      height: 30px;
      display: grid;
      place-items: center;
      border-radius: 7px;

      &.delete {
        background: none;
        border: 1px solid var(--danger);

        .icon {
          stroke: var(--danger);
        }

        &:hover {
          background: var(--danger);

          .icon {
            stroke: white;
          }
        }
      }

      &.edit {
        background: none;
        border: 1px solid var(--brand);

        .icon {
          stroke: var(--brand);
        }

        &:hover {
          background: var(--brand);

          .icon {
            stroke: white;
          }
        }
      }
    }
  }
}

.App {
  width: 100vw;
  height: 100vh;
}

button {
  background: var(--brand);
  border: 2px solid var(--brand);
  color: white;
  font-size: 1rem;
  border-radius: var(--radius);
  cursor: pointer;

  &:hover {
    background: var(--brand-dark);
  }

  &:disabled {
    color: white;
    background: var(--passive);
    border: 2px solid var(--passive);
    pointer-events: none;
    user-select: none;
  }
}

.ModalManager {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  isolation: isolate;
  pointer-events: none;

  .modal {
    pointer-events: all;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    isolation: isolate;
    display: flex;
    justify-content: center;
    align-items: center;

    .bg {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.456);
    }

    .wrapper {
      background: white;
      padding: 15px;
      border-radius: var(--radius);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      max-width: 40rem;

      .modalTitle {
        font-size: 1.2rem;
      }

      .modalBody {
        margin-bottom: 20px;
        text-align: center;
      }

      .buttons {
        display: flex;
        gap: 10px;

        button {
          font-size: 1rem;
          padding: 5px 10px;

          &.danger {
            background: var(--danger);
            border: 1px solid var(--danger);
          }
        }
      }
    }
  }
}
