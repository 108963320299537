.Input {
    border: var(--border);
    border-radius: var(--radius);
    padding: 5px 20px;
    padding-top: 20px;
    min-width: 20rem;
    position: relative;
    cursor:text;

    .placeholder {
        position: absolute;
        top: 0;
        font-size: .9rem;
        color: var(--passive);

        top: 50%;
        transform: translateY(-50%);
        color: black;
        font-size: 1rem;
        transition: all 0.25s ease;
        pointer-events: none;
    }

    &.focus {
        border-color: var(--brand);
        .placeholder {
            top: 0;
            transform: translateY(0);
            font-size: .9rem;
            color: var(--passive);
        }
    }

    input {
        background: none;
        border: none;
        font-size: 1rem;
        width: 100%;
        &:focus {
            outline: none;
        }
    }
}