.AccessForm {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: space-between;

    .art {
        object-fit: cover;
        width: 50vw;
    }
    
    .form {
        background: var(--surface-0);
        width: 50vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        button {
            padding: 10px;
            width: 100%;
        }

        .title {
            font-weight: 400;
        }

        .inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 20rem;

            .error {
                color: var(--danger);
                width: 100%;
                text-align: center;
            }

            p {
                text-align: center;
            }

            .links {
                display: flex;
                gap: 20px;

                a {
                    font-size: .8rem;
                    color: var(--passive);
                }
            }
        }
    }
}