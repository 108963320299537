@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

.Landing {
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(to bottom, white, white, white, white, white, rgb(139, 255, 139), green);
    height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    position: relative; 

    .middle-section {
        text-align: center; 
        position: absolute;
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%); 

        h2 {
            margin-bottom: 0.1em; 
        }

        p {
            margin-bottom: 1em;
            font-style: italic; 
        }

        .buttons {
            margin-top: 30px;
            display: flex;
            justify-content: center; 

        p5 {
            margin-top: 10px;
            text-decoration: none;
        }

            .button {
                background-color: #ffffff;
                color: rgb(0, 0, 0);
                text-decoration: none;
                text-align: center;
                padding: 10px 20px;
                margin: 0 10px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #cff0e2;
                }
            }
        }
    }

    .contact-button {
        position: absolute; 
        bottom: 20px; 
        left: 20px; 
        padding: 8px 16px;
        background-color: #ffffff; 
        color: rgb(0, 0, 0); 
        border: none; 
        border-radius: 5px; 
        font-size: 14px; 
        text-transform: uppercase;
        text-decoration: none; 
        cursor: pointer; 
        transition: background-color 0.3s ease; 

        &:hover {
            background-color: #a0ceff; 
        }

        &:focus {
            outline: none; 
        }
    }

    .logo-image {
        position: fixed;
        left: 40px;
        top: 40px;
        width: 100px;
        background: transparent
    }

    .header {
        background: white;
        color: black;
        padding: 10px 0;
        position: fixed;
        width: 100%;
        left: 0%;
        top: 0;
        z-index: 1;
        text-align: center;

        nav ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center; 

            li {
                margin: 0 10px; 

                a {
                    position: relative;
                    color: black;
                    text-decoration: none;
                    padding: 8px 15px;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 0; 
                        height: 2px;
                        background: black;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out;
                        opacity: 0; 
                    }

                    &:hover::after, &:focus::after {
                        width: 100%;
                        opacity: 1;
                    }

                    &.active-link::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        background: black;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        opacity: 1;
                    }
                }
            }
        }
    }
}
